
import { defineComponent, inject, onMounted, ref } from "vue";
import { register } from "vue-advanced-chat";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { Socket } from "socket.io-client";
import { AxiosRequestConfig } from "axios";
import JwtService from "@/core/services/JwtService";
import { onBeforeRouteLeave } from "vue-router";

interface ClassRoom {
  _id: string;
  name: string;
}

register();

export default defineComponent({
  name: "chat-teacher",
  components: {},
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const socket: Socket = inject("socket") as Socket;

    const sy = window.localStorage.getItem("activeSchoolarYear");
    const currentUserId = ref(store.getters.currentUser._id);
    const parentsData = ref<Array<any>>([]);
    const classRooms = ref<ClassRoom[]>([]);
    const selectedParent = ref<any>(undefined);
    const selectedRoom = ref<any>(null);
    const messagesLoaded = ref(false);
    const roomsLoaded = ref(false);

    const messageActions = ref([
      {
        name: "deleteMessage",
        title: "Delete Message",
        onlyMe: true,
      },
    ]);
    const textMessages = ref({
      ROOMS_EMPTY: t("chat.ROOMS_EMPTY"),
      ROOM_EMPTY: t("chat.ROOM_EMPTY"),
      NEW_MESSAGES: t("chat.NEW_MESSAGES"),
      MESSAGE_DELETED: t("chat.MESSAGE_DELETED"),
      MESSAGES_EMPTY: t("chat.MESSAGES_EMPTY"),
      CONVERSATION_STARTED: t("chat.CONVERSATION_STARTED"),
      TYPE_MESSAGE: t("chat.TYPE_MESSAGE"),
      SEARCH: t("chat.SEARCH"),
      IS_ONLINE: t("chat.IS_ONLINE"),
      LAST_SEEN: t("chat.LAST_SEEN"),
    });

    const rooms = ref<any[]>([]);
    const messages = ref<any[]>([]);
    const roomActions = ref([]);

    const visibleAddParent = ref(false);
    const loading = ref(true);

    const base_url = store.getters.serverConfigUrl?.base_url;

    const fetchAndUpdateRooms = async () => {
      let unseenMessages: any[] = [];
      await ApiService.get("/pt-chat/teacher/unseenMessages").then((res) => {
        unseenMessages = res.data;
      });

      const _rooms: any[] = [];
      let update = false;

      // get all parent chats
      await ApiService.get("/pt-chat/teacher/parentChats")
        .then((res) => {
          res.data.map((parent: any) => {
            const name = `${parent.father.firstName} ${parent.father.lastName} / ${parent.mother.firstName} ${parent.mother.lastName}`;
            const photo = parent.photo
              ? base_url + "/" + parent.photo.replaceAll("\\", "/")
              : "-";
            const status = parent.online
              ? { state: "online" }
              : { state: "false" };

            const lastMessage = {
              _id: parent.lastMessage._id,
              content: parent.lastMessage.message,
              senderId:
                parent.lastMessage.sentBy == "parent"
                  ? parent.lastMessage.parentId
                  : "0",
              username: name,
              saved: true,
              timestamp: new Date(parent.lastMessage.createdAt)
                .toString()
                .substring(16, 21),
              seen: parent.lastMessage.seen,
            };
            if (!rooms.value.find((room) => room.roomId == parent._id)) {
              update = true;
              _rooms.push({
                roomId: parent._id,
                roomName: name,
                unreadCount:
                  unseenMessages.find((p) => p._id == parent._id)?.count || 0,
                avatar: photo,
                lastMessage,
                users: [
                  {
                    _id: currentUserId.value,
                    username: "Teacher",
                    status: {
                      state: "online",
                      lastChanged: "today, 14:30",
                    },
                  },
                  {
                    _id: parent._id,
                    username: name,
                    status: status,
                  },
                ],
              });
            }
          });

          rooms.value.push(..._rooms);
          if (rooms.value.length > 0 && !update) {
            selectedRoom.value = rooms.value[0];
            socket.emit("pt-teacherSeen", {
              parentId: selectedRoom.value.roomId,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          roomsLoaded.value = true;
        });
    };

    onMounted(async () => {
      await fetchAndUpdateRooms();
      const trimester = JwtService.getTrimester();

      // get teacher's classrooms
      const classrooms: string[] = [];
      await ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: store.getters.currentUser._id,
          trimester: trimester,
        },
      })
        .then(({ data }) => {
          for (const classroom of data) {
            classRooms.value.push({
              _id: classroom.classRoom._id,
              name: classroom.classRoom.name,
            });
            classrooms.push(classroom.classRoom._id);
          }
        })
        .catch((e) => console.log(e));

      const match = {};
      match[`schoolarYearsHistory.${sy}`] = { $in: classrooms };

      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: {
                $arrayElemAt: ["$classRoom._id", 0],
              },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              parentId: {
                $arrayElemAt: ["$parent._id", 0],
              },
              parentPhoto: {
                $arrayElemAt: ["$parent.photo", 0],
              },
              father: {
                $arrayElemAt: ["$parent.father", 0],
              },
              mother: {
                $arrayElemAt: ["$parent.mother", 0],
              },
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          parentsData.value = data.reduce((acc: any, curr: any) => {
            const studentFullname = `${curr.firstName} ${curr.lastName}`;
            const parent = acc.find(
              (item: any) => item.parentId === curr.parentId
            );
            if (!parent) {
              acc.push({
                parentId: curr.parentId,
                father: curr.father,
                mother: curr.mother,
                students: [studentFullname],
              });
            } else {
              parent.students.push(studentFullname);
            }
            return acc;
          }, []);
        })
        .catch((e) => console.log(e))
        .finally(() => (loading.value = false));

      setCurrentPageBreadcrumbs(t("chat.chatWithParents"), []);

      roomsLoaded.value = true;
    });

    const cancelAddParent = () => {
      visibleAddParent.value = false;
      selectedParent.value = undefined;
    };

    const addNewParent = () => {
      visibleAddParent.value = true;
    };

    const handleAddParent = () => {
      if (selectedParent.value == undefined) return;

      const record = parentsData.value.find(
        (p) => p.parentId == selectedParent.value
      );

      const name = `${record.father.firstName} ${record.father.lastName} / ${record.mother.firstName} ${record.mother.lastName}`;
      const photo = record.parentPhoto
        ? base_url + "/" + record.parentPhoto.replaceAll("\\", "/")
        : "-";
      const room = {
        roomId: selectedParent.value,
        roomName: name,
        unreadCount: 0,
        avatar: photo,
        users: [
          {
            _id: currentUserId.value,
            username: "Teacher",
            status: {
              state: "online",
              lastChanged: "today, 14:30",
            },
          },
          {
            _id: record._id,
            username: name,
            status: {
              state: "offline",
            },
          },
        ],
      };

      rooms.value = [room, ...rooms.value];

      socket.emit("request-parent-status", {
        parentId: record._id,
      });

      cancelAddParent();
    };

    const fetchMessages = ({ room, options = {} }) => {
      selectedRoom.value = room;
      const _messages: any = [];

      messagesLoaded.value = false;

      room.unreadCount = 0;
      rooms.value.map((m) => {
        if (m.roomId == room.roomId) {
          m.unreadCount = 0;
        }
        return m;
      });

      // get messages from db of selected room (parent id)
      ApiService.get("/pt-chat/teacher/messages/" + room.roomId)
        .then((res) => {
          res.data.messages.map((msg) => {
            const message_files: any = [];
            if (msg.files && msg.files.length > 0) {
              msg.files.map((file) => {
                const splited = file.split(".");
                const splitedName = splited[splited.length - 2].split("--")[1];
                message_files.push({
                  name: splitedName,
                  type: splited[splited.length - 1],
                  url: base_url + "/" + file.replaceAll("\\", "/"),
                  preview: base_url + "/" + file.replaceAll("\\", "/"),
                });
              });
            }

            _messages.push({
              _id: msg.createdAt,
              messageId: msg._id,
              content: msg.message,
              senderId:
                msg.sentBy == "teacher" ? currentUserId.value : msg.parent,
              timestamp: new Date(msg.createdAt).toString().substring(16, 21),
              date: moment(msg.createdAt).format("MM/DD/YYYY"),
              files: message_files,
              seen: msg.seen,
              saved: true,
              deleted: Boolean(msg.deleted),
            });
          });

          setTimeout(() => {
            messagesLoaded.value = true;
            messages.value = _messages;

            socket.emit("pt-teacherSeen", {
              parentId: room.roomId,
            });
          }, 100);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const sendMessage = (message) => {
      const temp_id = Math.floor(new Date().getTime());
      setTimeout(() => {
        const _messages = [...messages.value];
        _messages.push({
          _id: temp_id, // temp id
          messageId: temp_id, // temp id
          content: message.content,
          senderId: currentUserId.value,
          timestamp: new Date().toString().substring(16, 21),
          date: moment().format("MM/DD/YYYY"),
          files: message.files,
        });
        messages.value = _messages;

        // if message has files upload them first
        if (message.files && message.files.length > 0) {
          const data = new FormData();

          for (const file of message.files) {
            data.append("files", file.blob, file.name + "." + file.extension);
          }

          ApiService.put("/pt-chat/upload", data as AxiosRequestConfig, {
            headers: {
              "Content-Type": `multipart/form-data;`,
            },
          })
            .then((res) => {
              socket.emit("pt-teacherMessage", {
                message: message.content,
                parentId: message.roomId,
                files: res.data,
                temp_id,
              });
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          socket.emit("pt-teacherMessage", {
            message: message.content,
            parentId: message.roomId,
            files: [],
            temp_id,
          });
        }
      }, 50);
    };

    const roomNewMessages = (roomId, count) => {
      const _rooms = [...rooms.value];
      _rooms.map((room) => {
        if (room.roomId == roomId) {
          room.unreadCount += count;
        }
        return room;
      });
      rooms.value = _rooms;
    };

    const openFile = ({ file }) => {
      if (file.action == "download") window.open(file.file.url, "_blank");
    };

    const deleteMessageHandler = ({ roomId, message }) => {
      socket.emit("pt-deleteMessage", {
        parentId: roomId,
        messageId: message.messageId,
      });
    };

    const onMessage = async ({
      sentBy,
      parentId,
      message,
      messageId,
      date,
      files,
      temp_id,
    }) => {
      const room = rooms.value.find((room) => room.roomId == parentId);
      if (!room) await fetchAndUpdateRooms(); // create new room for parent

      const _messages = [...messages.value];
      const message_files: any[] = [];

      if (files && files.length > 0) {
        files.map((file) => {
          const splited = file.split(".");
          const splitedName = splited[splited.length - 2].split("--")[1];
          message_files.push({
            name: splitedName,
            type: splited[splited.length - 1],
            url: base_url + "/" + file.replaceAll("\\", "/"),
            preview: base_url + "/" + file.replaceAll("\\", "/"),
          });
        });
      }

      if (sentBy == "teacher") {
        const msg = _messages.find((message) => message.messageId == temp_id);
        if (!msg) {
          // message sent by defferent socket
          _messages.push({
            _id: date,
            messageId: messageId,
            content: message,
            saved: true,
            seen: false,
            senderId: currentUserId.value,
            timestamp: new Date(date).toString().substring(16, 21),
            date: moment(date).format("MM/DD/YYYY"),
            files: message_files,
          });
        } else {
          // message saved in db
          msg.messageId = messageId;
          msg.saved = true;
          msg.seen = false;
          msg.files = message_files;
          msg.content = message;
        }
      } else {
        if (selectedRoom.value.roomId != parentId) {
          roomNewMessages(parentId, 1);
        } else {
          _messages.push({
            _id: date,
            messageId,
            content: message,
            saved: true,
            seen: false,
            senderId: parentId,
            timestamp: new Date(date).toString().substring(16, 21),
            date: moment(date).format("MM/DD/YYYY"),
            files: message_files,
          });
          socket.emit("pt-teacherSeen", {
            parentId,
          });
        }
      }

      rooms.value.map((m) => {
        if (m.roomId == parentId) {
          m.lastMessage = {
            _id: messageId,
            content: message,
            senderId: sentBy == "parent" ? parentId : currentUserId.value,
            timestamp: new Date(date).toString().substring(16, 21),
            new: true,
            saved: true,
            seen: false,
          };
        }
      });

      messages.value = _messages;
    };

    const onDelete = ({ messageId, parentId }) => {
      if (selectedRoom.value.roomId == parentId) {
        const _messages = [...messages.value];
        const message = _messages.find(
          (message) => message.messageId == messageId
        );
        if (message) {
          message.deleted = true;
          messages.value = _messages;
        }
      }
    };

    const onSeen = ({ parentId }) => {
      const _messages = [...messages.value];
      if (selectedRoom.value.roomId == parentId)
        _messages.map((message) => {
          message.seen = true;
          return message;
        });
      messages.value = _messages;

      rooms.value.map((room) => {
        if (room.roomId == parentId) room.lastMessage.seen = true;
      });
    };

    const onChatStatus = ({ type, parentId }) => {
      if (type == "parentOffline")
        rooms.value.map((room) => {
          if (room.roomId == parentId) room.users[1].status.state = "false";
          return room;
        });
      else if (type == "parentOnline")
        rooms.value.map((room) => {
          if (room.roomId == parentId) room.users[1].status.state = "online";
          return room;
        });
      else console.log("chat-status", type);
    };

    // listen to new messages from parent or admin (saved in db)
    socket.on("pt-teacherMessage", onMessage);

    socket.on("pt-deletedMessage", onDelete);

    socket.on("pt-parentSeen", onSeen);

    // listening for online/offline status
    socket.on("chat-status", onChatStatus);

    onBeforeRouteLeave(() => {
      socket.off("pt-teacherMessage", onMessage);
      socket.off("pt-deletedMessage", onDelete);
      socket.off("pt-parentSeen", onSeen);
      socket.off("chat-status", onChatStatus);
      return true;
    });

    return {
      t,
      currentUserId,
      rooms,
      messages,
      roomActions,
      visibleAddParent,
      parentsData,
      selectedParent,
      handleAddParent,
      cancelAddParent,
      addNewParent,
      fetchMessages,
      messagesLoaded,
      roomsLoaded,
      sendMessage,
      textMessages,
      messageActions,
      openFile,
      deleteMessageHandler,
    };
  },
});
